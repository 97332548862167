/*
 *
 * Application Main
 *
 */
!(function(APP) {
    'use strict';


    var breakpoint = 768;

    $.extend(true, APP, {
        breakpoint: breakpoint,
        init: {
            footerCollapse: () => {
                let collapseTriggerEl = $('footer .collapse-trigger');

                collapseTriggerEl.click(function() {
                    let collapseMenu = $(this).siblings('.collapse-menu');

                    if (APP.browser.bp()) {
                        collapseMenu.toggleClass('opened');
                        $(this).toggleClass('show');
                    }
                });

                if (!APP.browser.bp()) {
                    $('.collapse-menu').addClass('opened');
                }
            },
            mainSlide: () => {
                if (window.innerWidth < 998) {
                    setTimeout(function() {
                        $('.slick-slider').slick('unslick');
                    }, 100);
                }
            },
            rangeSlide: () => {

                function slide(results, minInpt, maxInpt, min, max) {
                    min = parseInt(min, 10);
                    max = parseInt(max, 10);
                    minInpt.val(min);
                    maxInpt.val(max);
                    results.find('.min').html(min.toLocaleString('tr-TR'));
                    results.find('.max').html(max.toLocaleString('tr-TR'));
                }

                $('#slider-range').each(function(i, e) {
                    var min = parseInt($(e).data('min'), 10),
                        max = parseInt($(e).data('max'), 10);

                    var minInpt = $($(e).data('target-min'));
                    var maxInpt = $($(e).data('target-max'));

                    var minVal = parseInt(minInpt.val(), 10) || 10000;
                    var maxVal = parseInt(maxInpt.val(), 10) || 5000000;

                    var results = $($(e).data('result'));

                    $(e).slider({
                        range: true,
                        min: min,
                        max: max,
                        values: [minVal, maxVal],
                        slide: function( event, ui ) {
                            slide(results, minInpt, maxInpt, ui.values[0], ui.values[1]);
                        }
                    }).trigger('slide');

                    slide(results, minInpt, maxInpt, minInpt.val(), maxInpt.val());
                });
            },
            toggleSelectBox: () => {
                let collapseTriggerEl = $('.aside-search-form .collapse-trigger');

                collapseTriggerEl.click(function() {
                    let collapseMenu = $(this).siblings('.collapse-menu');

                    if (APP.browser.bp()) {
                        collapseMenu.toggleClass('opened');
                        $(this).toggleClass('show');
                    }
                });
            },
            sumoSelect: () => {
                $('.sumo-select').SumoSelect({ // eslint-disable-line
                    nativeOnDevice: [],
                    csvDispCount: 1,
                    captionFormat: '{0} tane seçildi.',
                    captionFormatAllSelected: '{0} tane seçildi.'
                });
            },
            linkedSelect: () => {
                var options = {
                    attrTarget: 'data-select-target',
                    attrService: 'data-select-service',
                    attrFilter: 'data-select-service-asfilter',
                    method: 'POST',
                    // eslint-disable-next-line no-unused-vars
                    onBeforeFill: function ($source, $target, data) {},
                    // eslint-disable-next-line no-unused-vars
                    onAfterFill: function (target, source, data) {
                        if (!target && source) {
                            target = source;
                            source = null;
                        }
                    },
                    service: {
                        // eslint-disable-next-line no-unused-vars
                        onBeforeSend: function (service, data, serviceUri, options, base) {}
                    }
                };

                $.linkedSelect.init(options);
                $('.selectTrigger').val('-1').trigger('change');
                $('.trigger-change').trigger('change');
            },
            checkbox: () => {

                $('.form-check-input[value="-1"]').on('change', function() {
                    var name = this.name;
                    $('[name="' + name + '"]:not([value="-1"])').prop('checked', false);
                });
            }
        },

        INIT: function(options) {
            // APP init

            options = options || {};

            var fn;

            for (var i in this.init) {
                if ( Object.prototype.hasOwnProperty.call(this.init, i) && i.charAt(0) !== '_' && typeof(fn = this.init[i]) === 'function' ) {
                    fn.call(this, options && options[i] || options);
                }
            }

            return this;
        }
    });

})(window.APP = window.APP || {});

$(function() {
    'use strict';

    APP.browser = (function() {

        var is = APP.Helper.is,
            val, tmp,
            userAgent = APP.sanitizeXss(navigator.userAgent);

        var browser = {
            mobile: !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(userAgent),
            ios: !!/iPhone|iPad|iPod/i.test(userAgent),
            ie: (tmp = userAgent.toLowerCase().match(/MSIE ([^;]+)|rv:(11)\.[0-9]+/i)) ? parseInt(tmp[1] || tmp[2], 10) : false,
            edge: (tmp = userAgent.indexOf('Edge/')) > 0 ? parseInt(userAgent.substring(tmp + 5, userAgent.indexOf('.', tmp)), 10) : false,
            bp: function() {
                return $(window).width() < APP.breakpoint;
            }
        };

        var $el = $('html'); // document.documentElement

        for (var k in browser ) {
            if ( Object.prototype.hasOwnProperty.call(browser, k) ) {
                val = browser[k];

                if ( val && !is.function(val) ) {
                    $el.addClass(k);
                    if ( !is.boolean(val) ) {
                        $el.addClass(k + val);
                    }
                }
            }
        }

        APP.browser = browser;

        return browser;
    }());

    APP.scriptPath = APP.Helper.getScriptPath(['app.js', 'app.min.js', 'main.js']);

    APP.Helper.loadScript(APP.scriptPath + 'config.js', {
        success: function() {
            APP.INIT(CONFIG);
        },
        failed: function() {
            APP.INIT();
        }
    });
});
