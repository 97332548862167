/*
 *
 * Application Popup Plugin
 * using fancybox
 *
 */
!(function(root, factory) {
    'use strict';

    if ( typeof define === 'function' && define.amd ) {
        // AMD. Register as an anonymous module.
        define(['app', 'jquery'], factory);
    } else if ( typeof exports === 'object' ) {
        // Node/CommonJS
        module.exports = factory(require('app'), require('jquery'));
    } else {
        // Browser globals
        root.APP = root.APP || {};
        root.APP.init = root.APP.init || {};
        factory(root.APP, root.jQuery);
    }
}(this, function(APP, $) {
    'use strict';

    var _defaults = {
        base: {
            padding: 0,
            openEffect: 'ease',
            closeEffect: 'ease',
            helpers: {
                media: {}
            }
        },
        gallery: {
            nextEffect: 'fade',
            prevEffect: 'fade',
            closeClick: true,
            openSpeed: 150,
            closeSpeed: 150,
            helpers: {
                title: null
            }
        },
        galleryMedia: {
            rel: 'gallery'
        },
        pageLoad: {
            autoSize: false,
            height: 'auto',
            openEffect: 'none',
            closeEffect: 'none',
            width: '70%',
            maxWidth: '100%',
            padding: 20,
            wrapCSS: 'popup-noscroll',
            helpers: {
                title: null
            }
        }
    };

    function getOptions(section, extras) {

        section = section || '';

        return $.extend(true, {}, _defaults.base, _defaults[section] || {}, extras);
    }


    var _$ = {
        popup: function(opts) {

            var options = getOptions('base', $.extend({}, _defaults.base, opts.base));

            Fancybox.bind(".fancy-box", options); // eslint-disable-line
        },
        galleryMedia: function() {

            /* var options = $.extend({}, _defaults.base, getOptions('galleryMedia'), { selector: '.gallery-box' });

            $().fancybox(options); */

            Fancybox.bind('[data-fancybox="gallery"]', { // eslint-disable-line
                caption: function (fancybox, carousel, slide) {
                    return (
                        `${slide.index + 1} / ${carousel.slides.length} <br />` + slide.caption
                    );
                }
            });
        },
        gallery: function(opts) {

            var options = $.extend({}, {
                cache: false,
                url: null,
                type: 'POST',
                dataType: 'json'
            }, opts);

            $('.popup-gallery').on('click', function(e) {

                e.preventDefault();

                var rel = $(this).attr('rel');

                if ( rel ) {
                    $.ajax($.extend({}, options, {
                        data: {
                            gallery: rel
                        }
                    })).then(function(data) {
                        $.fancybox.open(data, getOptions('gallery'));
                    }).fail(function(/* err */) {
                        // console.log(err);
                    });
                }
            });
        },
        pageLoad: function(opts) {

            var $content = $('#popup-content');
            var $opts = $content.data('options');

            if ( !$content.length ) {
                return;
            }

            var options = $.extend(getOptions('pageLoad'), opts, {
                src: '#popup-content',
                type: 'inline'
            }, $opts);

            $.fancybox.open(options);
        }
    };

    APP.init.popups = function(opts) {

        opts = $.extend(_defaults, opts);

        _$.popup(opts);
        _$.galleryMedia(opts.galleryMedia);
        _$.gallery(opts.gallery);
        _$.pageLoad(opts.pageLoad);
    };
}));
